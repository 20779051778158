<template>
  <b-button
    :id="id"
    :block="isBlock"
    :disabled="isDisabled"
    :pill="isPill"
    :squared="isSquared"
    :title="false"
    :variant="variant"
    :to="to"
    :pressed="isPressed"
    :size="size"
    class="d-flex flex-row align-items-center justify-content-center form-button"
  >
    <template v-if="isLoading">
      <b-spinner small class="mr-2"></b-spinner>
      <span class="text-small">{{loadingText}}</span>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </b-button>
</template>

<script>
  /* eslint-disable no-console */
  export default {
    name: "FormButton",
    props: {
      id: String,
      type: {
        default: "button",
        type: String,
      },
      title: {
        default: "Button",
        type: String,
      },
      size: {
        default: "md",
        type: String,
      },
      variant: {
        default: "primary",
        type: String,
      },
      to: {
        default: null,
        type: String,
      },
      isBlock: {
        default: false,
        type: Boolean,
      },
      isPill: {
        default: false,
        type: Boolean,
      },
      isSquared: {
        default: false,
        type: Boolean,
      },
      isDisabled: {
        default: false,
        type: Boolean,
      },
      isLoading: {
        default: false,
        type: Boolean,
      },
      loadingText :{
        default: "Loading...",
        type: String
      },
      isPressed:{
        default: false,
        type: Boolean
      }
    },
  };
</script>
<style scoped>
  span.text-small {
    font-size: 0.9rem;
  }
  .form-button {
    min-height: 50px;
    border-radius: 8px;
    width: 180px;
    border: transparent;
    box-shadow: 0px 8px 16px var(--shadow-hard);
  }
</style>